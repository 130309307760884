// Generated by Framer (1c1e241)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NHNx_O691", "yE1nrLWsL", "WOgsKnN2k", "uxm_StLNW", "qZKz07OGL"];

const serializationHash = "framer-NvLdq"

const variantClassNames = {NHNx_O691: "framer-v-3nuv1q", qZKz07OGL: "framer-v-2hgx91", uxm_StLNW: "framer-v-68si78", WOgsKnN2k: "framer-v-yzkknw", yE1nrLWsL: "framer-v-1f6zyss"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Brand Logo": "NHNx_O691", "Header Logo Light": "qZKz07OGL", "Logo - Light Small": "uxm_StLNW", "Logo Dark": "yE1nrLWsL", "Primary - Small": "WOgsKnN2k"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NHNx_O691"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NHNx_O691", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-NvLdq", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-3nuv1q", className)} data-framer-name={"Brand Logo"} layoutDependency={layoutDependency} layoutId={"NHNx_O691"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({qZKz07OGL: {"data-framer-name": "Header Logo Light"}, uxm_StLNW: {"data-framer-name": "Logo - Light Small"}, WOgsKnN2k: {"data-framer-name": "Primary - Small"}, yE1nrLWsL: {"data-framer-name": "Logo Dark"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 393, intrinsicWidth: 897, pixelHeight: 393, pixelWidth: 897, sizes: "104px", src: "https://framerusercontent.com/images/43XCaJJcrNaKj2u13TgPIkbvj94.png", srcSet: "https://framerusercontent.com/images/43XCaJJcrNaKj2u13TgPIkbvj94.png?scale-down-to=512 512w, https://framerusercontent.com/images/43XCaJJcrNaKj2u13TgPIkbvj94.png 897w"}} className={"framer-1ix6as1"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"gEHSTnfYm"} {...addPropertyOverrides({qZKz07OGL: {background: {alt: "", fit: "fill", intrinsicHeight: 393, intrinsicWidth: 897, pixelHeight: 393, pixelWidth: 897, src: "https://framerusercontent.com/images/lWWfqUiffQVBF97bKlpQx9kQdO8.svg"}}, yE1nrLWsL: {background: {alt: "", fit: "fill", intrinsicHeight: 393, intrinsicWidth: 897, pixelHeight: 393, pixelWidth: 897, src: "https://framerusercontent.com/images/HaR8m5gTts8gqR6p959yqN1VR8.svg"}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-NvLdq [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NvLdq .framer-7i0i7r { display: block; }", ".framer-NvLdq .framer-3nuv1q { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-NvLdq .framer-1ix6as1 { flex: none; height: 34px; overflow: visible; position: relative; width: 104px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NvLdq .framer-3nuv1q { gap: 0px; } .framer-NvLdq .framer-3nuv1q > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-NvLdq .framer-3nuv1q > :first-child { margin-left: 0px; } .framer-NvLdq .framer-3nuv1q > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 104
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"yE1nrLWsL":{"layout":["auto","auto"]},"WOgsKnN2k":{"layout":["auto","auto"]},"uxm_StLNW":{"layout":["auto","auto"]},"qZKz07OGL":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 */
const Framerwa1lKZ58S: React.ComponentType<Props> = withCSS(Component, css, "framer-NvLdq") as typeof Component;
export default Framerwa1lKZ58S;

Framerwa1lKZ58S.displayName = "Original Logo Copy";

Framerwa1lKZ58S.defaultProps = {height: 34, width: 104};

addPropertyControls(Framerwa1lKZ58S, {variant: {options: ["NHNx_O691", "yE1nrLWsL", "WOgsKnN2k", "uxm_StLNW", "qZKz07OGL"], optionTitles: ["Brand Logo", "Logo Dark", "Primary - Small", "Logo - Light Small", "Header Logo Light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerwa1lKZ58S, [])